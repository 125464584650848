import useAsync from '@finst/core/src/scripts/hooks/use-async';
import localLogger from '@finst/core/src/scripts/loggers/local-logger';
import { AppError } from '@finst/core/src/scripts/models/app-error';
import isAppError from '@finst/core/src/scripts/services/app-error/is-app-error';
import deleteFromBiometricKeychain from '@finst/core/src/scripts/services/biometric-keychain/delete-from-biometric-keychain';
import isBiometricKeychainAvailable from '@finst/core/src/scripts/services/biometric-keychain/is-biometric-keychain-available';
import isSavedInBiometricKeychain from '@finst/core/src/scripts/services/biometric-keychain/is-saved-in-biometric-keychain';
import saveToBiometricKeychain from '@finst/core/src/scripts/services/biometric-keychain/save-to-biometric-keychain';
import verifyInBiometricKeychain from '@finst/core/src/scripts/services/biometric-keychain/verify-in-biometric-keychain';
import generateId from '@finst/core/src/scripts/utils/generate-id';
import { useCallback, useState } from 'preact/compat';
import deleteBiometricCode from '../services/identity/delete-biometric-code';
import setBiometricCode from '../services/identity/set-biometric-code';
import useDeviceInfo from './use-device-info';
export default function useBiometricCode(email) {
    const deviceInfo = useDeviceInfo();
    const biometricKeychainKey = email && (deviceInfo === null || deviceInfo === void 0 ? void 0 : deviceInfo.uuid) ? `${email}__${deviceInfo.uuid}` : '';
    const [status, setStatus] = useState('unknown');
    useAsync(async ()=>{
        try {
            await isBiometricKeychainAvailable();
        } catch  {
            return setStatus('unsupported');
        }
        if (!biometricKeychainKey) {
            return setStatus('not-enabled');
        }
        try {
            await isSavedInBiometricKeychain(biometricKeychainKey);
            setStatus('enabled');
        } catch  {
            setStatus('not-enabled');
        }
    // the presence/absence of `deviceInfo` indicates if webview API layer is loaded or not
    }, [
        biometricKeychainKey
    ]);
    const disable = useCallback(async ()=>{
        await deleteFromBiometricKeychain(biometricKeychainKey);
        setStatus('not-enabled');
        await deleteBiometricCode();
    }, [
        biometricKeychainKey
    ]);
    const enable = useCallback(async ()=>{
        const code = generateId();
        await saveToBiometricKeychain(biometricKeychainKey, code);
        setStatus('enabled');
        return setBiometricCode({
            code
        });
    }, [
        biometricKeychainKey
    ]);
    const verify = useCallback(async (message)=>{
        let code;
        try {
            code = await verifyInBiometricKeychain(biometricKeychainKey, message);
        } catch (error) {
            if (error && isAppError(error) && error.code === 'KeyPermanentlyInvalidatedException') {
                // proceed in case of an error
                await disable().catch(localLogger.error);
            }
            throw error;
        }
        // Catch weird iOS bug when the code in keychain is empty
        if (!code) {
            // proceed in case of an error
            await disable().catch(localLogger.error);
            throw new AppError({
                code: 'NotFound',
                field: 'code'
            });
        }
        return code;
    }, [
        biometricKeychainKey,
        disable
    ]);
    return {
        status,
        enable,
        disable,
        verify
    };
}
